@use '@angular/material' as mat;

/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
// Variables

//PALETTE
/* For use in src/lib/core/theming/_palette.scss */
$mat-primary: (
  50: #e6e6e6,
  100: #c0c0c0,
  200: #979797,
  300: #6d6d6d,
  400: #4d4d4d,
  500: #2e2e2e,
  600: #292929,
  700: #232323,
  800: #1d1d1d,
  900: #121212,
  A100: #ef6b6b,
  A200: #e93d3d,
  A400: #f30000,
  A700: #da0000,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$mat-secondary: (
  50 : #ffffff,
  100 : #ffffff,
  200 : #ffffff,
  300 : #ffffff,
  400 : #ffffff,
  500 : #ffffff,
  600 : #ffffff,
  700 : #ffffff,
  800 : #ffffff,
  900 : #ffffff,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffffff,
  A700 : #ffffff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$mat-tertiary: (
  50 : #faf5ed,
  100 : #f2e6d2,
  200 : #ead6b4,
  300 : #e1c695,
  400 : #dab97f,
  500 : #d4ad68,
  600 : #cfa660,
  700 : #c99c55,
  800 : #c3934b,
  900 : #b9833a,
  A100 : #ffffff,
  A200 : #ffecd3,
  A400 : #ffd5a0,
  A700 : #ffca86,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);



$body-font: Pompiere Regular, sans-serif;
$heading-font: Pompiere Regular, sans-serif;

$primary-color: mat.get-color-from-palette($mat-primary, 500);
$secondary-color: mat.get-color-from-palette($mat-secondary, 900);
$tertiary-color: mat.get-color-from-palette($mat-tertiary, 900);
$tertiary-light-color: mat.get-color-from-palette($mat-tertiary, 500);

$body-color: #ffffff;
$heading-color: #515353;
$body-text-color: #707070;
$theme-color: #8AB8CC;

/*bootstrap Color*/
$danger: #FF2E2E;
$success: #46A34A;
$warning: #ffb22b;
$info: #1e88e5;
$inverse: #2f3d4a;
$muted: #C8C8C8;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdf8;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;

$danger-dark: #e6294b;
$success-dark: #1eacbe;
$warning-dark: #e9ab2e;
$primary-dark: #6352ce;

/*Normal Color*/
$white: #ffffff;
$purple: #7460ee;
$theme-color-alt: #26c6da;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$radius: 4px;


@mixin xs() {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin gt-sm() {
  @media (min-width: 960px) {
    @content;
  }
}

@mixin lt-sm() {
  @media (max-width: 960px) {
    @content;
  }
}

@mixin gt-md() {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin lt-md() {
  @media (max-width: 1280px) {
    @content;
  }
}

@mixin gt-lg() {
  @media (min-width: 1920px) {
    @content;
  }
}

@mixin lt-lg() {
  @media (max-width: 1920px) {
    @content;
  }
}

@mixin xl() {
  @media (min-width: 1920px) and (max-width: 4999.99px) {
    @content;
  }
}

@mixin lt-xl() {
  @media (max-width: 1919.99px) {
    @content;
  }
}

@mixin lg() {
  @media (min-width: 1280px) and (max-width: 1919.99px) {
    @content;
  }
}
