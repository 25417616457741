/* You can add global styles to this file, and also import other style files */
@import 'front/variable';
@import 'front/video';
@import 'front/theme';
@import 'front/fonts';

@import 'front/mixins/spacing';
@import 'front/mixins/color';
@import 'front/mixins/font';
@import 'front/mixins/display';
@import 'front/mixins/borders';

@import 'front/component/global';
@import 'front/component/heading';

@import 'front/component/header';
@import 'front/component/sidebar';
@import 'front/component/spinner';
@import 'front/component/card';
@import 'front/component/dashboard';

@import 'front/responsive';


/* MODAL */
.cdk-overlay-backdrop {
  &.cdk-overlay-backdrop-showing {
    background: rgba(0, 0, 0, .32) !important;
    opacity: 1 !important;
  }
}


// SELECT RADIUS
.custom-field-radius.mat-form-field-appearance-outline .mat-form-field-outline-start,
.custom-field-radius.mat-form-field-appearance-outline .mat-form-field-outline-end,
.custom-field-radius.mat-form-field-appearance-outline .mat-form-field-outline-gap {
  border-color: $tertiary-color !important;
}

.custom-field-radius.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
  border-top-color: rgba(0, 0, 0, 0) !important;
}

.custom-field-radius.mat-form-field-appearance-outline .mat-form-field-flex {
  line-height: 17px;
  padding: 0 0.75em 0 2.25em !important
}

.mat-form-field-outline-start {
  border-radius: 28px 0 0 28px !important;
  min-width: 28px !important;
}

.mat-form-field-outline-end {
  border-radius: 0 28px 28px 0 !important;
}

.mat-form-field-label {
  color: $tertiary-color;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: #474949;
}

.custom-field-radius.mat-form-field-appearance-outline .mat-form-field-outline {
  color: $tertiary-color !important;

  label {
    color: $tertiary-color !important;
  }
}

.custom-field-radius.mat-form-field-appearance-outline .mat-form-field-outline-start,
.custom-field-radius.mat-form-field-appearance-outline .mat-form-field-outline-end,
.custom-field-radius.mat-form-field-appearance-outline .mat-form-field-outline-gap {
  border-width: 2px !important;
}

// INPUT
.mat-form-field-appearance-legacy .mat-form-field-label {
  color: $primary-color !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline,
.mat-form-field-ripple {
  background-color: $primary-color !important;
}

.datepicker-field {
  &.mat-form-field-appearance-standard {
    .mat-form-field-flex {
      padding-top: 0;
    }

    .mat-form-field-infix {
      padding: 0 0;
    }
  }

  .mat-form-field-wrapper {
    // height: 100px;
    .mat-datepicker-toggle {
      float: right;
      margin: -6px 0 -6px 0;
      display: inline-block;
    }

    .mat-datepicker-input {
      top: 7px;
      left: 3px;
      position: absolute;
    }
  }
}

.video-box {
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
  max-width: 871px;

  &-thumb {
    width: 100%;
  }

  iframe {
    @include lt-sm() {
      height: 300px !important;
    }
  }

}

#modalVideo {
  padding: 0 !important;
  background-color: #000000;
}

.color-standard {
  color: #2E2E2E;
}

.cursor-pointer {
  cursor: pointer
}

.btn {
  cursor: pointer;
}

.big-btn {
  width: 304px;
  height: 49px;
  font-size: 14px;
  background: $primary-color;
  color: #FFFFFF;
  border: 1.5px solid #2f2f2f;
  border-radius: 31px !important;
  box-shadow: none !important;
}

.big-btn-outlined {
  width: 304px;
  height: 49px;
  font-size: 14px;
  background: transparent;
  color: $primary-color;
  border: 2px solid $primary-color !important;
  border-radius: 31px !important;
  box-shadow: none !important;
  font-family: "Sofia Pro" !important;
}

.animated-container {
  width: 100%;
  margin: 5px 0 0;
  padding: 0;

  &-text {
    // animation: fadeInBottom 1.1s both;
    display: inline-block;
    font-size: 37px;
  }
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}

.rotate {
  transform: rotate(0);

  &.left {
    transform: rotateY(180deg);
  }

  &.up {
    transform: rotate(-45deg);
  }

  &.down {
    transform: rotate(45deg);
  }

  &.revert{
    &.right{
      transform: rotateY(180deg);
    }
    &.left {
      transform: rotateY(360deg);
    }
    &.up {
      transform: rotate(45deg);
    }
    &.down {
      transform: rotate(-45deg);
    }
  }
}

// ANIMATE STEPS TRANSITIONS
.animated {
  opacity: 1;
  transform: translate(0, 0);
  transition: transform 0.6s ease, opacity 1s ease;

  &-delay-1 {
    transition-delay: 0.4s !important;
  }

  &-delay-2 {
    transition-delay: 0.8s !important;
  }

  &-delay-3 {
    transition-delay: 1.1s !important;
  }

  &.animated-bottom-to-top {
    transform: translateY(0);
  }

  &.animated-top-to-bottom {
    transform: translateY(0);
  }

  &.animated-left-to-right {
    transform: translateX(0);
  }

  &.animated-right-to-left {
    transform: translateX(0);
  }
}

.container-footer{
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.body-transition {

  .js-media-video {
    opacity: 0 !important;
    visibility: hidden !important;
    transform: scale(1.5);
  }

  .animated {
    opacity: 0;

    &.animated-bottom-to-top {
      transform: translateY(50px);

      &.animated-left-to-right {
        transform: translate(-150px, 50px);
      }

      &.animated-right-to-left {
        transform: translate(150px, 50px);
      }
    }

    &.animated-top-to-bottom {
      transform: translateY(-50px);

      &.animated-left-to-right {
        transform: translate(150px, -50px);
      }

      &.animated-right-to-left {
        transform: translate(-150px, -50px);
      }
    }

    &.animated-left-to-right {
      transform: translate(-150px, 0px);
    }

    &.animated-right-to-left {
      transform: translate(150px, 0px);
    }
  }

  .container-footer {
    opacity: 0;
    visibility: hidden;
    // transform: translate(0, 50px);
  }
}


//TOGGLE BUTTON PADRÃO
.toggle-group {
  display: flex;
  gap: 10px;
}

.toggle-group input[type="radio"] {
  display: none;
}

.toggle-group label {
  padding: 8px 16px;
  border: 2px solid $primary-color;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  width: 33%;
  text-align: center;


  @include lt-md() {
    padding: 8px 6px;
  }
}

.toggle-group input[type="radio"]:checked + label {
  background-color: $primary-color;
  color: #fff;
}

.show-password {
  transform: scale(1.3);
}

.mat-input-element {
  font-family: "Sofia Pro" !important;
}

.min-h-100 {
  min-height: 100%;
}

.bg-white {
  background-color: #FFFFFF;
}

.bg-dark {
  min-height: 100vh !important;
  background-color: $primary-color;

  .container-page-header{
    opacity: 0;
  }
}

.page-inside {
  display: flex;
  min-height: calc(80vh - 110px);
}

.bottom-sheet {
  &-panel {
    .mat-bottom-sheet-container {
      padding: 0;
      box-shadow: none;
      background-color: transparent;
    }
  }

  &-header {
    width: 100%;
    float: left;
    display: block;

    .bottom-sheet-top {
      width: 100%;
      display: block;
      margin: 0 0 -2px 0;
    }
  }

  &-body {
    width: 100%;
    float: left;
    display: block;
    padding: 0 20px 20px;
    background-color: $white;
    border-left: 1px solid #353535;
    border-right: 1px solid #353535;
  }
}

.font-regular{
  font-weight: 400;
}

.font-secondary{
  font-size: 16px !important;
  font-family: 'Sofia pro';
}

.container {
  max-width: 1028px;
  margin: 0 auto;

  &-page {
    width: 100%;
    display: flex;
    min-height: 90vh;
    flex-direction: column;
    justify-content: space-between;

    &-header {
      // margin-bottom: 40px; // UTILIZAR PARGEM DENTRO DO STEP OU CRIAR UMA VARIAÇÃO PARA HEADER COM MARGEM PQ NEM TODAS TELAS TEM MARGEM
      font-size: 16px;
      padding: 0 25px 20px;
      font-family: "Sofia Pro", arial;
      border-bottom: 1px solid #C8C8C8;

      strong{
        font-family: "Sofia Pro", arial;
      }

      &.space-bottom {
        margin-bottom: 40px;
      }
    }
  }

  &-body {
    flex-grow: 1;
    display: flex;
    // overflow: auto;
    //  max-height: 75vh;
    padding: 15px 0 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transition: opacity 0.4s ease;

    &.items {
      &-end {
        align-items: end;
      }

      &-center {
        align-items: center;
      }

      &-start {
        align-items: start;
      }
    }

    &.justify {
      &-end {
        justify-content: end;
      }

      &-center {
        justify-content: center;
      }

      &-start {
        justify-content: start;
      }

      &-between {
        justify-content: space-between;
      }
    }

    img {
      max-width: 100%;
    }

    h2 {
      padding: 0 25px;
      font-size: 35px;
      line-height: 42px;

      &.title-small {
        font-size: 30px;
        line-height: 35px;
        // font-weight: 600;
      }
    }

    p {
      padding: 0 25px;
      font-size: 26px;

      &.text-small {
        font-size: 22px;
        // font-weight: 600;
      }

      &.text-medium {
        font-size: 19px;
        font-weight: 600;
      }

      &.text-ultrasmall {
        font-weight: normal;
        font-size: 19px;
        color: #2e2e2e;
      }

      &.text-big {
        font-size: 32px;
        font-weight: 900;
      }

      &.text-center {
        text-align: center;
      }
    }

    &-desc-questionnaire {
      font-family: 'Sofia Pro'
    }

    &.positioned {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 10px;
    }

    &-interact-item {
      cursor: pointer;
      display: flex;
      flex-direction: column;

      span {
        margin: 10px 0 0;
        font-size: 40px;
      }
    }

    &.padding-y {
      padding: 15px 0 15px;
    }

    &.grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-items: center;
      align-items: center;

      span {
        font-size: 30px;
      }

      .cards {
        gap: 10px;
        display: flex;
        flex-direction: column;
      }
    }

    &-step-7 {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;

      &-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 60px;
        margin: 20px auto;
        width: 100%;

        &.active {
          // color: $white;
          // background-color: $primary-color;
          border-left: 5px solid $primary-color;
        }

        p {
          font-size: 18px;
        }
      }
    }

    &-step-9 {
      display: flex;
      align-items: center;
      flex-direction: row;

      &-content {
        text-align: end;

        span {
          font-size: 25px;
        }

        p {
          font-size: 14px;

          .text-ultrasmall{
            font-size: 14px !important;
          }
        }
      }
    }

    .feelings-options-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 15px;
      justify-items: center;
      margin: 0 0 25px;

      &.full-btn {
        width: 90% !important;
        display: flex;
        flex-direction: column;
        gap: 20px;

        &-button {
          width: 100%;
        }
      }

      &-button {
        border: 2px solid #000;
        border-radius: 31px;
        padding: 15px 20px;
        background-color: transparent;
        cursor: pointer;
        transition: background-color 0.3s;
        text-align: center;
        min-width: 160px;
        min-height: 49px;
        font-family: "Sofia Pro";
        font-size: 14px;
        font-weight: bold;

        &.selected {
          background-color: $primary-color;
          color: #fff;
        }
      }
    }

    &-form {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      align-items: center;

      &-image {
        width: 44px;
        height: 44px;
        margin: 0 10px;
        min-width: 44px;
      }
    }

    .card-mails {
      bottom: 0;
      display: flex;
      margin: 0 auto;
      max-width: 260px;
      align-items: start;
      position: absolute;
      padding-bottom: 19px;
      justify-content: end;
      flex-direction: column;
      // transform: translate(10%, 100%);

      &-panel{
        margin: -80px auto 0px;
        display: flex;
        max-width: 390px;
        position: relative;
        align-items: center;
        justify-content: center;
      }

      &-title {
        top: 20px;
        left: 0;
        width: 100%;
        margin-top: 100px;
        text-align: center;
        font-size: 32px !important;
      }

      p {
        font-size: 22px;
      }
    }
  }

  &-footer {
    gap: 15px;
    z-index: 10;
    display: flex;
    padding: 0 25px;
    min-width: 100%;
    min-height: 50px;
    // overflow: hidden;
    // position: relative;
    align-items: center;
    visibility: visible;
    transition: opacity 0.4s ease, transform 0.4s ease 1s, visibility 0.4s ease 1s;

    &.column {
      flex-direction: column;
    }

    &.justify {
      &-end {
        justify-content: end;
      }

      &-center {
        justify-content: center;
      }

      &-start {
        justify-content: start;
      }

      &-between {
        justify-content: space-between;
      }
    }

    .btn {
      transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s ease, bottom 0.3s ease;
    }

    &-video {
      background: url('assets/images/arrow-up.gif') no-repeat center bottom;
      background-size: 40px;

      .btn {
        opacity: 0;
        visibility: hidden;
        transform: translate(0, 50px);
      }

      &:hover {
        .btn {
          opacity: 1;
          visibility: visible;
          transform: translate(0, 0);
        }
      }
    }
  }
}

button {
  touch-action: manipulation;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.btn {
  &-fixed{
    // left: 5%;
    bottom: 10px;
    margin: 0 auto;
    position: fixed;
    max-width: 978px;
    width: 90% !important;
    // transition-delay: 1s;
  }
  &.after-view {
    opacity: 0;
    visibility: hidden;
    transform: translateY(30px);
    transition: opacity 0.3s ease 0.5s, transform 0.3s ease 0.5s;

    &.is-active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
    }
  }
}

.btn-hide {
  opacity: 0;
  visibility: hidden;
  transform: translateY(30px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s ease;

  &.is-active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.btn-primary {
  height: 50px;
  border-radius: 24.5px;
  background: #2f2f2f;
  border: 1.5px solid #2f2f2f;
  color: $white;
  font-weight: bold;
  font-size: 14px;
}

.btn-primary-outlined {
  height: 50px;
  border-radius: 24.5px;
  background: $white;
  border: 1.5px solid #2f2f2f;
  color: #2f2f2f;
  font-weight: bold;
  font-size: 14px;
}

.btn-hit {
  width: 100%;
  position: relative;

  .btn {
    z-index: 10;
    position: relative;
    color: $primary-color;
    background-color: transparent;
    transition: color 0.4s ease;
  }

  .progress-container {
    top: 0;
    left: 0;
    width: 100%;
    border: none !important;
    overflow: hidden;
    position: absolute;
    border-radius: 24.5px;

    .progress-bar {
      height: 50px;
      border-radius: 24.5px;
      background-color: $primary-color;
    }
  }
}

.mat-radio-label {
  display: flex !important;
  align-items: center !important;
  font-family: "Sofia Pro" !important;
}

.mat-radio-label-content {
  white-space: normal;
}

.choose-radio {
  padding: 18px;
  display: flex;
  background: $white;
  align-items: center;
  margin: 0 25px 20px;
  color: $primary-color;
  border-radius: 16px 16px 0 0;
  border-bottom: 1px solid #D1D1D1;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out, border-radius 0.1s ease-in-out, border-color 0.1s ease-in-out;

  &:last-of-type {
    border: none;
  }

  .mat-radio-button {
    .mat-radio-inner-circle {
      background-color: transparent;
      transition: background-color 0.3s ease-in-out;
    }

    .mat-radio-outer-circle {
      border-color: currentColor;
      transition: border-color 0.3s ease-in-out;
    }
  }

  .mat-radio-checked .mat-radio-outer-circle {
    border-color: $primary-color !important;
  }

  &.selected {
    background: #2E2E2E;
    color: $white;
    border-bottom: 0;
    border-radius: 16px;
    border-color: transparent;

    .mat-radio-button {
      .mat-radio-inner-circle {
        background-color: $white;
      }

      .mat-radio-outer-circle {
        border-color: $white !important;
      }
    }
  }
}

.h-80-vh {
  height: 80vh;
}

.line-divider {
  border-bottom: 1px solid #D1D1D1;
}

.js-media-video {
  opacity: 0;
  transform: scale(1);
  transition: opacity 0.5s ease, visibility 0.5s ease, transform 0.5s ease;
}


// EARTHQUAKE
.shake {
  animation: shake-animation 0.5s;
  animation-iteration-count: infinite;
}

@keyframes shake-animation {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

video{
  background: $white !important;
}

// SVG
.svg-icon{
  svg{
    width: 100%;
    font-family: $body-font;

    path{
      font-family: $body-font;
    }
  }

  &.large{
    svg{
      width: 100%;
    }
  }
  &.middle{
    svg{
      width: 50%;
    }
  }
  &.small{
    svg{
      width: 35%;
    }
  }
  &.ultra-small{
    svg{
      width: 20%;
    }
  }
}


// RANGE SLIDE
.ng5-slider .ng5-slider-pointer,
.ng5-slider .ng5-slider-selection{
  background-color: #2F2F2F !important;
}
.ng5-slider .ng5-slider-pointer:after {
  content: '';
  width: 14px;
  height: 14px;
  position: absolute;
  top: 9px;
  left: 9px;
  border-radius: 7px;
  background: #fff;
}

.ng5-slider .ng5-slider-pointer.ng5-slider-active:after {
  background-color: $white !important;
}

.btn-sound{
  height: 36px;
  top: 12px;
  right: 10px;
  z-index: 100;
  font-weight: bold;
  position: absolute;

  &.intro{
    top: 42px;
  }

  svg{
    width: 40px !important;
  }
}

.link-custom{
  font-size: 28px;
  font-family: "Sofia Pro", arial;
  color: $primary-color;
}



.list-horizontal{
  display: flex;
  justify-content: space-between;

  .choose-radio{
    margin: 0 0px !important;
  }

  .mat-radio-label{
    display: flex !important;
    flex-direction: column !important;

    &-content{
      padding-left: 0 !important;
    }
  }

}
