.d-flex {
  display: flex;
}

.d-none{
  display: none;
}

.d-block{
  display: block;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.align-items-start {
  align-items: start;
}

.justify-content-center{
  justify-content: center;
}

.justify-content-end{
  justify-content: end;
}

.justify-content-between{
  justify-content: space-between;
}

.justify-content-around{
  justify-content: space-around;
}

.justify-content-evenly{
  justify-content: space-evenly;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}
